<template>
  <div class="body">
    <div class="go-back" @click="toBack">
      <img
        style="width: 15px; margin-right: 5px"
        src="../../assets/images/Examine_img/back.png"
        alt=""
      />
      <span style="margin-right: 15px">返回</span>
    </div>
    <div
      class="mid_box"
      style="margin-left: 30px"
      v-if="!$route.query.isNoShowDrawBtn"
    >
      <el-dropdown style="padding-right: 10px" @command="handleDropdown1">
        <el-button type="primary" class="edit"
          >抽取题目<i class="el-icon-circle-plus-outline el-icon--right"></i
        ></el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            :command="1"
            :disabled="mixType.auditName == undefined || rowItem.status == 2"
            >现场检查</el-dropdown-item
          >
          <el-dropdown-item
            :command="2"
            :disabled="mixType.fileName == undefined || rowItem.status == 2"
            >文件落地</el-dropdown-item
          >
          <!-- <el-dropdown-item
            :disabled="mixType.deptHeaderName == undefined"
            :command="3"
            >部门领导审核</el-dropdown-item
          > -->
        </el-dropdown-menu>
      </el-dropdown>
      <el-button type="primary" class="edit" @click="showAddQues = true"
        >新增提问<i class="el-icon-circle-plus-outline el-icon--right"></i
      ></el-button>
      <div class="toSave1" @click="confirmSave">
        <img
          style="width: 15px"
          src="../../assets/images/Examine_img/sava.png"
          alt=""
        />
        <span>完成检查</span>
      </div>
      <img
        style="margin: 0 10px"
        src="../../assets/images/Examine_img/del1.png"
        @click="delItem"
      />

      <el-dropdown @command="handleDropdown2">
        <el-button type="primary" class="edit"
          >添加题目<i class="el-icon-circle-plus-outline el-icon--right"></i
        ></el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            :command="1"
            :disabled="mixType.auditName == undefined"
            >现场检查</el-dropdown-item
          >
          <el-dropdown-item
            :command="2"
            :disabled="mixType.fileName == undefined"
            >文件落地</el-dropdown-item
          >
          <!-- <el-dropdown-item
            :command="3"
            :disabled="mixType.deptHeaderName == undefined"
            >部门领导审核</el-dropdown-item
          > -->
        </el-dropdown-menu>
      </el-dropdown>
      <el-button
        :disable="disable"
        style="margin-left: 10px"
        type="primary"
        class="edit"
        @click="getPdf()"
        >打印检查表</el-button
      >
      <el-button
        :disable="disable"
        style="margin-left: 10px"
        type="primary"
        class="edit"
        @click="exportExcel()"
        >导出检查表</el-button
      >
    </div>
    <el-table
      ref="table"
      :data="tableData.slice(start, end)"
      style="width: 100%"
      :height="elementHeight"
      highlight-current-row
      border
      @row-click="handleRowClick"
      :row-class-name="tableRowClassName"
      :row-style="selectedstyle"
      @cell-dblclick="handleCell"
      :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
      >
      <!-- <el-table-column type="selection" width="50"> </el-table-column> -->
      <el-table-column type="index" :index="(e) => (currentPage - 1) * curSize + e + 1" label="序号" width="60" > </el-table-column>
      <el-table-column
        prop="mixAuditType"
        label="类型"
        width="80"
        v-if="!$route.query.show7sTable"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.mixAuditType == 0">现场检查</span>
          <span v-if="scope.row.mixAuditType == 1">文件检查</span>
          <span v-if="scope.row.mixAuditType == 2">考核细则</span>
          <span v-if="scope.row.mixAuditType == 3">内外审检查</span>
          <span v-if="scope.row.mixAuditType == 4">部门领导审核</span>
        </template>
      </el-table-column>
      <el-table-column prop="fileName" label="文件名称" width="150">
      </el-table-column>
      <!--除能内外审绑questionRemark, 其他都绑定question -->
      <el-table-column prop="" label="题目">
        <template slot-scope="scope">
          <span>{{
            scope.row.mixAuditType == 3
              ? scope.row.questionRemark
              : scope.row.question
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="auditMethod"
        label="审核方式"
        width="170"
        v-if="!$route.query.show7sTable"
      >
      </el-table-column>
      <el-table-column
        prop="auditResult"
        :label="
          mixType.checkStatus != undefined
            ? '审核结果/是否需要整改'
            : mixType.deptHeaderName
            ? '记录'
            : '审核结果'
        "
        align="center"
        width="300"
      >
        <!-- mixAuditType:0现场/1文件/2考核/3内外审 -->
        <template slot-scope="scope">
          <!-- 文件/现场检查/考核细则 -->
          <div
            class="auditResult_box"
            v-if="
              scope.row.mixAuditType != 3 &&
              scope.row.mixAuditType != 4 &&
              !$route.query.show7sTable
            "
          >
            <div
              @click="changeResult(scope.row, 1, '符合')"
              :class="{ auditactive: scope.row.auditResult === 1 }"
            >
              符合
            </div>
            <div
              @click="changeResult(scope.row, 0, '不符合')"
              :class="{ auditactive: scope.row.auditResult === 0 }"
            >
              不符合
            </div>
            <div
              @click="changeResult(scope.row, 2, '纠正')"
              :class="{ auditactive: scope.row.auditResult === 2 }"
            >
              纠正
            </div>
            <div
              @click="changeResult(scope.row, 3, '改进')"
              :class="{ auditactive: scope.row.auditResult === 3 }"
            >
              改进
            </div>
            <!-- //考核细则没有不适用 -->
            <div
              v-if="scope.row.mixAuditType != 2"
              @click="changeResult(scope.row, 4, '不适用')"
              :class="{ auditactive: scope.row.auditResult === 4 }"
            >
              不适用
            </div>
          </div>
          <!-- 内外审  checkStatus：0自查，1自纠-->
          <div class="auditResult_box" v-if="scope.row.mixAuditType == 3">
            <div
              @click="changeResult(scope.row, 1, '是')"
              :class="{ auditactive: scope.row.auditResult == 1 }"
            >
              <span v-if="mixType.checkStatus == 0">是</span>
              <span v-else>已符合</span>
            </div>
            <div
              @click="changeResult(scope.row, 0, '否')"
              :class="{ auditactive: scope.row.auditResult == 0 }"
            >
              <span v-if="mixType.checkStatus == 0">否</span>
              <span v-else>是</span>
            </div>
            <div
              v-if="mixType.checkStatus == 1"
              @click="changeResult(scope.row, 3, '不合适')"
              :class="{ auditactive: scope.row.auditResult == 3 }"
            >
              不合适
            </div>
          </div>
          <!-- 7s不适用 -->
          <div class="auditResult_box" v-show="$route.query.show7sTable">
            <!-- <div @click="changeResult(scope.row, 1)" :class="{ auditactive: scope.row.auditResult === 1 }">5分(符合)</div>
            <div @click="changeResult(scope.row, 3)" :class="{ auditactive: scope.row.auditResult === 3 }">3分(改进)</div>
            <div @click="changeResult(scope.row, 0)" :class="{ auditactive: scope.row.auditResult === 0 }">1分(不符)</div>
            <div @click="changeResult(scope.row, 4)" :class="{ auditactive: scope.row.auditResult === 4 }">0分(不适用)</div> -->

            <div @click="changeResult(scope.row, 5)" :class="{ auditactive: scope.row.auditResult === 5 }">5分</div>
            <div @click="changeResult(scope.row, 4)" :class="{ auditactive: scope.row.auditResult === 4 }">4分</div>
            <div @click="changeResult(scope.row, 3)" :class="{ auditactive: scope.row.auditResult === 3 }">3分</div>
            <div @click="changeResult(scope.row, 2)" :class="{ auditactive: scope.row.auditResult === 2 }">2分</div>
            <div @click="changeResult(scope.row, 1)" :class="{ auditactive: scope.row.auditResult === 1 }">1分</div>
            <div @click="changeResult(scope.row, 0)" :class="{ auditactive: scope.row.auditResult === 0 && scope.row.isSuitable === 0}">0分</div>
            <div @click="changeResult(scope.row, 1 ,'7S不适用')" :class="{ auditactive: scope.row.isSuitable === 1 && scope.row.auditResult === 0 }">不适用</div>
          </div>
          <!-- 部门领导审核 -->
          <div class="auditResult_box" v-if="scope.row.mixAuditType == 4">
            <div
              @click="changeResult(scope.row, 1)"
              :class="{ auditactive: scope.row.auditResult == 1 }"
            >
              记录
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="imagesCount"
        label="照片列表"
        align="center"
        width="100px
        "
      >
        <template slot-scope="scope">
          <div
            class="img_box"
            style="padding: 0 10px"
            @click="openImg(scope.row)"
          >
            <img src="../../assets/images/Examine_img/add.png" alt="" />
            <span v-show="scope.row.imagesCount === 0">请添加图片</span>
            <span v-show="scope.row.imagesCount > 0">{{
              scope.row.imagesCount
            }}</span>
            <img src="../../assets/images/Examine_img/picture.png" alt="" />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="lawAsk" label="审核意见（双击列表）" width="170">
        <template slot-scope="scope">
          <input
            v-if="curRow.id === scope.row.id && columnID === scope.column.id"
            type="text"
            @focus="compare(scope.row.lawAsk)"
            @blur="Updata(scope.row, scope.row.lawAsk)"
            v-model="scope.row.lawAsk"
          />
          <span v-else>{{ scope.row.lawAsk }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="查看答案"
        width="95"
        v-if="!mixType.deptHeaderName"
      >
        <template slot-scope="scope">
          <el-button
            type="primary"
            class="button"
            plain
            @click="isChoose(scope.row)"
            >点击查看</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="answer"
        label="操作"
        width="90"
        v-if="$route.query.isNoShowDrawBtn && !$route.query.show7sTable"
      >
        <template slot-scope="scope">
          <el-button
            v-if="controlPageIndex != 2"
            type="success"
            class="button"
            plain
            @click="showOADialog(scope.row)"
            >发起OA</el-button
          >
          <el-button
            v-if="controlPageIndex == 2"
            type="primary"
            class="button"
            plain
            @click="clickSureBtn(scope.row, 1)"
            >确定</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="answer"
        label="操作"
        width="130"
        v-if="$route.query.isNoShowDrawBtn && $route.query.show7sTable"
      >
        <template slot-scope="scope">
          <el-button
            type="success"
            class="button"
            plain
            @click="table7sIsPass(1, scope.row)"
            >通过</el-button
          >
          <el-button
            v-if="controlPageIndex == 2"
            type="primary"
            class="button"
            plain
            @click="clickSureBtn(scope.row, 2)"
            >确定</el-button
          >
          <!-- <el-button
            type="primary"
            class="button"
            plain
            @click="table7sIsPass(0, scope.row)"
            >驳回</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-sizes="[10, 20, 50]"
      :page-size="curSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData.length"
      >
    </el-pagination>
    <el-dialog
      title="新增提问"
      :visible.sync="showAddQues"
      width="38%"
      class="addQuestionDialog"
      >
      <el-form ref="form" label-width="100px">
        <el-form-item label="审核类型" class="text">
          <el-select v-model="typeId" clearable placeholder="请选择">
            <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="问题描述" class="text">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入内容"
            v-model="question"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toAddQues">确定提问</el-button>
        <el-button @click="showAddQues = false">取消</el-button>
      </span>
    </el-dialog>
    <!-- 现场检查抽题 -->
    <el-dialog title="抽取题目" :visible.sync="dialogVisible1" width="35%">
      <div style="margin-bottom: 20px" class="text">
        <span style="margin: 10px">所属公司:</span>
        <el-select
          disabled
          v-model="company"
          placeholder="请选择"
          class="Company_choose"
        >
          <el-option
            v-for="item in companyLisy"
            :key="item.value"
            :label="item.companyName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-bottom: 20px" class="text">
        <span style="margin: 10px">所属区域:</span>
        <el-select v-model="areaId" placeholder="请选择" class="Company_choose">
          <el-option
            v-for="item in areaList"
            :key="item.value"
            :label="item.areaName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-bottom: 20px" class="text">
        <div style="display: flex">
          <span style="margin: 10px">抽取题目:</span>
          <span
            @click="chooseCt"
            style="margin: 10px; color: #4274c0; cursor: pointer"
            ><i class="el-icon-plus"></i> 添加范畴</span
          >
        </div>
        <div style="display: flex">
          <span style="margin: 10px; margin-left: 1%"
            >&ensp;&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
          >
          <div class="topic">
            <div>涉及方面</div>
            <div>总题数</div>
            <div>抽题数目</div>
            <img src="../../assets/images/Examine_img/top.png" alt="" />
          </div>
        </div>
        <el-collapse-transition>
          <div>
            <div
              style="display: flex"
              v-for="(item, index) in titlelist1"
              :key="index"
            >
              <span
                style="
                  margin: 10px;
                  margin-left: 1%;
                  margin-bottom: 0px;
                  margin-top: 0px;
                "
                >&ensp;&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              >
              <div class="topic1">
                <div>{{ item.auditTypeName }}</div>
                <div class="Centernumbet">
                  <span>{{ item.titleCount }}</span>
                </div>
                <div id="number">
                  <img
                    @click="sub(item)"
                    src="../../assets/images/Examine_img/slices/del1.png"
                    alt=""
                  />
                  <input
                    type="text"
                    style="ime-mode: disabled"
                    @keyup="test(item)"
                    v-model="item.extractCount"
                  />
                  <img
                    @click="plus(item)"
                    src="../../assets/images/Examine_img/slices/plus1.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </el-collapse-transition>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toReAdd1">确定抽题</el-button>
        <el-button @click="dialogVisible1 = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="添加范畴"
      :visible.sync="showCtdata"
      width="30%"
      :before-close="handleClose"
      >
      <div style="height: 300px; overflow: hidden; overflow-y: auto">
        <el-tree
          :data="Ctdata"
          show-checkbox
          node-key="id"
          ref="tree"
          @check="iScheck"
          highlight-current
          :props="defaultProps"
        >
        </el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showCtdata = false">取 消</el-button>
        <el-button type="primary" @click="gettitle1">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 文件检查抽题 -->
    <el-dialog title="抽取题目" :visible.sync="dialogVisible2" width="35%">
      <div style="margin-bottom: 20px" class="text">
        <span style="margin: 10px">所属公司:</span>
        <el-select
          v-model="company"
          placeholder="请选择"
          class="Company_choose"
          disabled
        >
          <el-option
            v-for="item in companyLisy"
            :key="item.value"
            :label="item.companyName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-bottom: 20px" class="text">
        <span style="margin: 10px">所属部门:</span>
        <el-select
          v-model="fileDept"
          placeholder="请选择"
          class="Company_choose"
          disabled
        >
          <el-option
            v-for="item in fileDeptList"
            :key="item.value"
            :label="item.deptmentName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-bottom: 20px" class="text">
        <span style="margin: 10px">所属岗位:</span>
        <el-select
          v-model="filePost"
          placeholder="请选择"
          class="Company_choose"
          disabled
        >
          <el-option
            v-for="item in filePostList"
            :key="item.value"
            :label="item.postName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-bottom: 20px">
        <div style="display: flex">
          <span style="margin: 10px">抽取题目</span>
          <el-table
            :data="titlelist2"
            @selection-change="selectionDraw"
            style="width: 100%"
          >
            <el-table-column prop="fileName" label="文件名称" width="150">
            </el-table-column>
            <el-table-column prop="fileNo" label="文件编号"> </el-table-column>
            <el-table-column prop="titleCount" label="总题数">
            </el-table-column>
            <el-table-column type="selection" width="55"> </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toReAdd2">确定抽题</el-button>
        <el-button @click="dialogVisible2 = false">取消</el-button>
      </span>
    </el-dialog>
    <!-- 部门领导审核抽题 -->
    <el-dialog title="抽取题目" :visible.sync="dialogVisible5" width="35%">
      <div style="margin-bottom: 20px">
        <span style="margin: 10px">所属公司:</span>
        <el-select
          v-model="company"
          placeholder="请选择"
          class="Company_choose"
          disabled
        >
          <el-option
            v-for="item in companyLisy"
            :key="item.value"
            :label="item.companyName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-bottom: 20px">
        <span style="margin: 10px">所属部门:</span>
        <el-select
          v-model="departmentHeads"
          placeholder="请选择"
          class="Company_choose"
          disabled
        >
          <el-option
            v-for="item in fileDeptList"
            :key="item.value"
            :label="item.deptmentName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-bottom: 20px">
        <div style="display: flex">
          <span style="margin: 10px">抽取题目</span>
          <el-table
            :data="titlelist5"
            @selection-change="selectionDraw5"
            style="width: 100%"
          >
            <el-table-column prop="fileName" label="文件名称" width="150">
            </el-table-column>
            <el-table-column prop="fileNo" label="文件编号"> </el-table-column>
            <el-table-column prop="titleCount" label="总题数">
            </el-table-column>
            <el-table-column type="selection" width="55"> </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toReAdd5">确定抽题</el-button>
        <el-button @click="dialogVisible5 = false">取消</el-button>
      </span>
    </el-dialog>

    <div class="draggable" :hidden="!dialogVisibleresult">
      <div class="header">
        {{ curRow.mixAuditType != 4 ? '审核结果' : '审核记录' }}
      </div>
      <div style="padding: 10px 20px">
        <el-form label-width="100px" :inline="true">
          <el-form-item label="审核结果：" v-if="curRow.mixAuditType != 4 && !$route.query.show7sTable">
            <span v-if="AuditStatus == 0">{{curRow.mixAuditType == 3 ? mixType.checkStatus == 0 ? '否' : '需要整改' : '不符合'}}</span>
            <span v-if="AuditStatus == 1">符合</span>
            <span v-if="AuditStatus == 3">改进</span>
            <span v-if="AuditStatus == 2">纠正</span>
            <span v-if="AuditStatus == 4">不适用</span>
          </el-form-item>
          <div v-else>
            <el-form-item label="审核结果：">
              <span v-if="AuditStatus == 0">0分</span>
              <span v-if="AuditStatus == 1">1分</span>
              <span v-if="AuditStatus == 2">2分</span>
              <span v-if="AuditStatus == 3">3分</span>
              <span v-if="AuditStatus == 4">4分</span>
              <span v-if="AuditStatus == 5">5分</span>
              <span v-if="AuditStatus == '7S不适用'">不适用</span>
            </el-form-item>
          </div>
          <!-- 现场、文件、考核细则 -->
          <el-form-item
            v-if="(curRow.mixAuditType != 3 && curRow.mixAuditType != 4) && !$route.query.show7sTable"
          >
            <el-radio
              v-show="AuditStatus == 0"
              v-model="radio"
              label="轻微不符合"
              >轻微不符合</el-radio
            >
            <el-radio
              v-show="AuditStatus == 0"
              v-model="radio"
              label="严重不符合"
              >严重不符合</el-radio
            >
          </el-form-item>
        </el-form>
        <el-form label-width="100px">
          <el-form-item
            :label="curRow.mixAuditType == 4 ? '记录：' : '审核意见：'"
            v-if="curRow.mixAuditType != 3"
          >
            <el-input
              v-model="Adoption"
              type="textarea"
              :rows="curRow.mixAuditType == 4 ? 4 : 2"
            ></el-input>
          </el-form-item>
          <el-form-item
            style="position: relative"
            label="违反条款："
            v-show="
              (AuditStatus === 0 || AuditStatus === 3 || AuditStatus === 2) &&
              curRow.mixAuditType != 3 &&
              curRow.mixAuditType != 4 && !this.$route.query.show7sTable
            "
          >
            <span
              style="
                color: red;
                margin-right: 5px;
                font-size: 19px;
                position: absolute;
                left: -94px;
                top: -2px;
              "
              >*</span
            >
            <el-input v-model="curRow.violatedTerms"></el-input>
          </el-form-item>
          <el-form-item
            label-width="20px"
            v-show="
              (AuditStatus === 0 || AuditStatus === 3 || AuditStatus === 2) &&
              curRow.mixAuditType != 3 &&
              curRow.mixAuditType != 4 && !this.$route.query.show7sTable
            "
          >
            <el-button
              :type="btnList.indexOf(i) == -1 ? '' : 'primary'"
              v-for="(i, index) in [
                '环境',
                '安全',
                '质量',
                'SA8000',
                '不按文件执行',
                '违反法规',
              ]"
              :key="index"
              @click="handleBtn(i)"
              >{{ i }}</el-button
            >
          </el-form-item>
          <!-- 内外审 -->
          <el-form-item
            :label="mixType.checkStatus == 0 ? '未完成原因：' : '整改措施：'"
            v-if="curRow.mixAuditType == 3"
          >
            <el-input
              v-model="curRow.correctMeasure"
              type="textarea"
              :rows="2"
              placeholder="请输入"
            ></el-input>
          </el-form-item>

          <el-form-item v-if="curRow.mixAuditType == 3" label="完成时间：">
            <el-date-picker
              v-model="curRow.completeTime"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="上传照片：">
            <el-upload
              action=" https://jsonplaceholder.typicode.com/posts/"
              list-type="picture-card"
              ref="upload"
              :on-preview="handlePictureCardPreview"
              :http-request="uploadFile"
              :headers="token"
              :key="uploadKey"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <div style="padding-bottom: 10px">已上传图片</div>
            <div class="img-list">
              <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="item"
                  :preview-src-list="srcList"
                  @mouseover="overimg(index)"
                >
                </el-image>
                <i
                  class="el-icon-delete-solid"
                  v-show="index == Curindex"
                  style="color: #e30d0d"
                  @click="delImg(item)"
                ></i>
              </span>
            </div>
          </el-form-item>
        </el-form>
        <span slot="footer" class="footer">
          <el-button @click="dialogVisibleresult = false">取消</el-button>
          <el-button type="primary" @click="toSure">确认</el-button>
        </span>
      </div>
    </div>

    <el-dialog title="查看答案" :visible.sync="dialogVisibleAsk" width="40%">
      <div style="font-size: 18px">
        答案：{{
          curRow.mixAuditType == 3 ? curRow.improvement : curRow.answer
        }}
      </div>
      <div style="font-size: 18px">图片：</div>
      <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
        <el-image
          style="width: 100px; height: 100px"
          :src="item"
          :preview-src-list="srcList"
          @mouseover="overimg(index)"
        >
        </el-image>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleAsk = false"
          >确 定</el-button
        >
        <el-button @click="dialogVisibleAsk = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 查看/添加图片 -->
    <el-dialog
      title="查看/添加图片"
      :visible.sync="dialogVisibleimg"
      width="40%"
      >
      <div style="padding-bottom: 10px">上传图片</div>
      <el-upload
        action=" https://jsonplaceholder.typicode.com/posts/"
        list-type="picture-card"
        ref="upload"
        :on-preview="handlePictureCardPreview"
        :http-request="uploadFile"
        :headers="token"
        :key="uploadKey"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <div style="padding: 10px 0">已上传图片</div>
      <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
        <el-image
          style="width: 100px; height: 100px"
          :src="item"
          :preview-src-list="srcList"
          @mouseover="overimg(index)"
        >
        </el-image>
        <i
          class="el-icon-delete-solid"
          v-show="index === Curindex"
          @click="delImg(item)"
        ></i>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toPostImg">确 定</el-button>
        <el-button @click="dialogVisibleimg = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 发起OA -->
    <el-dialog
      class="error-dialog"
      title="发起OA"
      :visible.sync="showOA"
      width="25%"
      >
      <el-form label-width="90px">
        <el-form-item label="用户编号">
          <el-input v-model="userNoOA" disabled></el-input>
        </el-form-item>
        <el-form-item label="原因状态">
          <el-input v-model="audirR" disabled></el-input> </el-form-item
        ><el-form-item label="审核意见">
          <el-input v-model="curRow.lawAsk" disabled></el-input>
        </el-form-item>
        <el-form-item label="违反条款">
          <el-input v-model="curRow.violatedTerms" disabled></el-input>
        </el-form-item>
        <el-form-item label="内审外审">
          <el-select
            class="elselect"
            v-model="auditTypeOA"
            placeholder="请选择"
          >
            <el-option
              v-for="item in [
                { name: '内审', value: 0 },
                { name: '外审', value: 1 },
              ]"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showOA = false">取消</el-button>
        <el-button type="primary" @click="requreOA">确认</el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="error-dialog error-dialog2"
      title="导出excel"
      :visible.sync="showExportExal"
      width="80%"
      top="5vh"
      >
      <el-table
        v-loading="sureExportExal"
        :data="tableData"
        style="width: 100%"
        height="63vh"
        border
        id="exportTable"
        :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
      >
        <!-- <el-table-column type="selection" width="50"> </el-table-column> -->
        <el-table-column prop="mixAuditType" label="类型" width="150">
          <template slot-scope="scope">
            <span v-if="scope.row.mixAuditType == 0">现场检查</span>
            <span v-if="scope.row.mixAuditType == 1">文件检查</span>
            <span v-if="scope.row.mixAuditType == 2">考核细则</span>
            <span v-if="scope.row.mixAuditType == 3">内外审检查</span>
            <span v-if="scope.row.mixAuditType == 4">部门领导审核</span>
          </template>
        </el-table-column>
        <el-table-column prop="fileName" label="文件名称" width="180">
        </el-table-column>
        <!--除能内外审绑questionRemark, 其他都绑定question -->
        <el-table-column prop="" label="题目" width="300">
          <template slot-scope="scope">
            <span>{{
              scope.row.mixAuditType == 3
                ? scope.row.questionRemark
                : scope.row.question
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="auditMethod" label="审核方式" width="230">
        </el-table-column>
        <el-table-column
          prop="auditResult"
          :label="
            mixType.checkStatus != undefined
              ? '审核结果/是否需要整改'
              : mixType.deptHeaderName
              ? '记录'
              : '审核结果'
          "
          align="center"
          width="300"
        >
          <!-- mixAuditType:0现场/1文件/2考核/3内外审 -->
          <template slot-scope="scope">
            <!-- 文件/现场检查/考核细则 -->
            <div
              class="auditResult_box"
              v-if="scope.row.mixAuditType != 3 && scope.row.mixAuditType != 4"
            >
              <div :class="{ auditactive: scope.row.auditResult === 1 }">
                符合
              </div>
              <div :class="{ auditactive: scope.row.auditResult === 0 }">
                不符合
              </div>
              <div :class="{ auditactive: scope.row.auditResult === 2 }">
                纠正
              </div>
              <div :class="{ auditactive: scope.row.auditResult === 3 }">
                改进
              </div>
              <!-- //考核细则没有不适用 -->
              <div
                v-if="scope.row.mixAuditType != 2"
                :class="{ auditactive: scope.row.auditResult === 4 }"
              >
                不适用
              </div>
            </div>
            <!-- 内外审  checkStatus：0自查，1自纠-->
            <div class="auditResult_box" v-if="scope.row.mixAuditType == 3">
              <div :class="{ auditactive: scope.row.auditResult == 1 }">
                <span v-if="mixType.checkStatus == 0">是</span>
                <span v-else>已符合</span>
              </div>
              <div :class="{ auditactive: scope.row.auditResult == 0 }">
                <span v-if="mixType.checkStatus == 0">否</span>
                <span v-else>是</span>
              </div>
              <div
                v-if="mixType.checkStatus == 1"
                :class="{ auditactive: scope.row.auditResult == 3 }"
              >
                不合适
              </div>
            </div>
            <!-- 部门领导审核 -->
            <div class="auditResult_box" v-if="scope.row.mixAuditType == 4">
              <div :class="{ auditactive: scope.row.auditResult === 1 }">
                记录
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="imagesCount"
          label="照片列表"
          align="center"
          width="190px
        "
        >
          <template slot-scope="scope">
            <div class="img_box">
              <img src="../../assets/images/Examine_img/add.png" alt="" />
              <span v-show="scope.row.imagesCount === 0">请添加图片</span>
              <span
                v-show="scope.row.imagesCount > 0"
                style="margin-right: 50%"
                >{{ scope.row.imagesCount }}</span
              >
              <img src="../../assets/images/Examine_img/picture.png" alt="" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="lawAsk" label="审核意见（双击列表）" width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.lawAsk }}</span>
          </template>
        </el-table-column>
        <el-table-column label="查看答案" width="300">
          <template slot-scope="scope">
            <span>{{ scope.row.answer }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="answer"
          label="发起OA"
          width="150"
          v-if="$route.query.isNoShowDrawBtn"
        >
          <template>
            <el-button type="success" class="button" plain>发起OA</el-button>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showExportExal = false">取消</el-button>
        <el-button
          type="primary"
          @click="handleExportExal"
          v-loading="sureExportExal"
          >确认导出</el-button
        >
      </span>
    </el-dialog>

 <!-- pdf打印表格 -->
  <el-table
      :data="tableData"
      style="width: 100%; position: absolute; left: -100%; z-index: -999"
      border
      id="pdfDom"
      :row-style="selectedstyle"
      :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
      >
      <el-table-column type="selection" width="50"> </el-table-column>
      <el-table-column
        prop="mixAuditType"
        label="类型"
        width="80"
        v-if="!$route.query.show7sTable"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.mixAuditType == 0">现场检查</span>
          <span v-if="scope.row.mixAuditType == 1">文件检查</span>
          <span v-if="scope.row.mixAuditType == 2">考核细则</span>
          <span v-if="scope.row.mixAuditType == 3">内外审检查</span>
          <span v-if="scope.row.mixAuditType == 4">部门领导审核</span>
        </template>
      </el-table-column>
      <el-table-column prop="fileName" label="文件名称" width="150">
      </el-table-column>
      <!--除能内外审绑questionRemark, 其他都绑定question -->
      <el-table-column prop="" label="题目">
        <template slot-scope="scope">
          <span>{{
            scope.row.mixAuditType == 3
              ? scope.row.questionRemark
              : scope.row.question
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="auditMethod"
        label="审核方式"
        width="170"
        v-if="!$route.query.show7sTable"
      >
      </el-table-column>
      <el-table-column
        prop="auditResult"
        :label="
          mixType.checkStatus != undefined
            ? '审核结果/是否需要整改'
            : mixType.deptHeaderName
            ? '记录'
            : '审核结果'
        "
        align="center"
        width="230"
      >
        <!-- mixAuditType:0现场/1文件/2考核/3内外审 -->
        <template slot-scope="scope">
          <!-- 文件/现场检查/考核细则 -->
          <div
            class="auditResult_box"
            v-if="
              scope.row.mixAuditType != 3 &&
              scope.row.mixAuditType != 4 &&
              !$route.query.show7sTable
            "
          >
            <div
              @click="changeResult(scope.row, 1, '符合')"
              :class="{ auditactive: scope.row.auditResult === 1 }"
            >
              符合
            </div>
            <div
              @click="changeResult(scope.row, 0, '不符合')"
              :class="{ auditactive: scope.row.auditResult === 0 }"
            >
              不符合
            </div>
            <div
              @click="changeResult(scope.row, 2, '纠正')"
              :class="{ auditactive: scope.row.auditResult === 2 }"
            >
              纠正
            </div>
            <div
              @click="changeResult(scope.row, 3, '改进')"
              :class="{ auditactive: scope.row.auditResult === 3 }"
            >
              改进
            </div>
            <!-- //考核细则没有不适用 -->
            <div
              v-if="scope.row.mixAuditType != 2"
              @click="changeResult(scope.row, 4, '不适用')"
              :class="{ auditactive: scope.row.auditResult === 4 }"
            >
              不适用
            </div>
          </div>
          <!-- 内外审  checkStatus：0自查，1自纠-->
          <div class="auditResult_box" v-if="scope.row.mixAuditType == 3">
            <div
              @click="changeResult(scope.row, 1, '是')"
              :class="{ auditactive: scope.row.auditResult == 1 }"
            >
              <span v-if="mixType.checkStatus == 0">是</span>
              <span v-else>已符合</span>
            </div>
            <div
              @click="changeResult(scope.row, 0, '否')"
              :class="{ auditactive: scope.row.auditResult == 0 }"
            >
              <span v-if="mixType.checkStatus == 0">否</span>
              <span v-else>是</span>
            </div>
            <div
              v-if="mixType.checkStatus == 1"
              @click="changeResult(scope.row, 3, '不合适')"
              :class="{ auditactive: scope.row.auditResult == 3 }"
            >
              不合适
            </div>
          </div>
          <!-- 7s不适用 -->
          <div class="auditResult_box" v-show="$route.query.show7sTable">
            <!-- <div
              @click="changeResult(scope.row, 1)"
              :class="{ auditactive: scope.row.auditResult === 1 }"
            >
              5分(符合)
            </div>
            <div
              @click="changeResult(scope.row, 3)"
              :class="{ auditactive: scope.row.auditResult === 3 }"
            >
              3分(改进)
            </div>
            <div
              @click="changeResult(scope.row, 0)"
              :class="{ auditactive: scope.row.auditResult === 0 }"
            >
              1分(不符)
            </div>
            <div
              @click="changeResult(scope.row, 4)"
              :class="{ auditactive: scope.row.auditResult === 4 }"
            >
              0分(不适用)
            </div> -->
            <div @click="changeResult(scope.row, 5)" :class="{ auditactive: scope.row.auditResult === 5 }">5分</div>
            <div @click="changeResult(scope.row, 4)" :class="{ auditactive: scope.row.auditResult === 4 }">4分</div>
            <div @click="changeResult(scope.row, 3)" :class="{ auditactive: scope.row.auditResult === 3 }">3分</div>
            <div @click="changeResult(scope.row, 2)" :class="{ auditactive: scope.row.auditResult === 2 }">2分</div>
            <div @click="changeResult(scope.row, 1)" :class="{ auditactive: scope.row.auditResult === 1 }">1分</div>
            <div @click="changeResult(scope.row, 0)" :class="{ auditactive: scope.row.auditResult === 0 && scope.row.isSuitable === 0}">0分</div>
            <div @click="changeResult(scope.row, 1 ,'7S不适用')" :class="{ auditactive: scope.row.isSuitable === 1 && scope.row.auditResult === 0 }">不适用</div>
          </div>
          <!-- 部门领导审核 -->
          <div class="auditResult_box" v-if="scope.row.mixAuditType == 4">
            <div
              @click="changeResult(scope.row, 1)"
              :class="{ auditactive: scope.row.auditResult == 1 }"
            >
              记录
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="imagesCount"
        label="照片列表"
        align="center"
        width="100px
        "
      >
        <template slot-scope="scope">
          <div
            class="img_box"
            style="padding: 0 10px"
            @click="openImg(scope.row)"
          >
            <img src="../../assets/images/Examine_img/add.png" alt="" />
            <span v-show="scope.row.imagesCount === 0">请添加图片</span>
            <span v-show="scope.row.imagesCount > 0">{{
              scope.row.imagesCount
            }}</span>
            <img src="../../assets/images/Examine_img/picture.png" alt="" />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="lawAsk" label="审核意见（双击列表）" width="170">
        <template slot-scope="scope">
          <input
            v-if="curRow.id === scope.row.id && columnID === scope.column.id"
            type="text"
            @focus="compare(scope.row.lawAsk)"
            @blur="Updata(scope.row, scope.row.lawAsk)"
            v-model="scope.row.lawAsk"
          />
          <span v-else>{{ scope.row.lawAsk }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="查看答案"
        width="95"
        v-if="!mixType.deptHeaderName"
      >
        <template slot-scope="scope">
          <el-button
            type="primary"
            class="button"
            plain
            @click="isChoose(scope.row)"
            >点击查看</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="answer"
        label="操作"
        width="90"
        v-if="$route.query.isNoShowDrawBtn && !$route.query.show7sTable"
      >
        <template slot-scope="scope">
          <el-button
            v-if="controlPageIndex != 2"
            type="success"
            class="button"
            plain
            @click="showOADialog(scope.row)"
            >发起OA</el-button
          >
          <el-button
            v-if="controlPageIndex == 2"
            type="primary"
            class="button"
            plain
            @click="clickSureBtn(scope.row, 1)"
            >确定</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="answer"
        label="操作"
        width="130"
        v-if="$route.query.isNoShowDrawBtn && $route.query.show7sTable"
      >
        <template slot-scope="scope">
          <el-button
            type="success"
            class="button"
            plain
            @click="table7sIsPass(1, scope.row)"
            >通过</el-button
          >
          <el-button
            v-if="controlPageIndex == 2"
            type="primary"
            class="button"
            plain
            @click="clickSureBtn(scope.row, 2)"
            >确定</el-button
          >
          <!-- <el-button
            type="primary"
            class="button"
            plain
            @click="table7sIsPass(0, scope.row)"
            >驳回</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>

  </div>
</template>

<script>
import { get, post, put, deleteMethod, baseURL } from '../../api/http'
import { compressImage } from '../../modules/CompressImage'
import imgTypeChange from '../../modules/imgChange'
import { exportExcel } from '../../modules/ExportExcel'
import axios from 'axios'
export default {
  data() {
    return {
      token: {
        Authorization:
          'Bearer ' + JSON.parse(window.sessionStorage.getItem('token')),
      },
      BUrl: baseURL[process.env.NODE_ENV + ''],
      htmlTitle: '混合审核',
      showExportExal: false,
      sureExportExal: false,
      user: {},
      tableData: [],
      elementHeight: 0,
      tableRowIndex: null,
      dialogVisible: false,
      companyLisy: [],
      rowItem: {},
      curRow: {},
      btnList: [],
      mixType: {},
      uploadKey: 1,
      // 分页
      currentPage: 1,
      curSize: 10,
      start: 0,
      end: 10,
      // 新增提问
      typeId: null,
      typeList: [],
      showAddQues: false,
      question: '',
      // 抽题
      isAddQuestion: false,
      // 现场抽题
      dialogVisible1: false,
      areaList: [],
      titlelist1: [],
      showCtdata: false,
      areaId: '',
      Ctdata: [],
      listID: [],
      nodeID: [],
      nodeKey: [],
      defaultProps: {
        label: 'auditTypeName',
        children: 'auditTypelist',
      },
      // 文件检查抽题
      dialogVisible2: false,
      fileDept: '',
      fileDeptList: [],
      filePost: '',
      filePostList: [],
      titlelist2: [],
      selectDrawVlue: [],
      // 部门领导审核抽题
      departmentHeads: '',
      selectDrawVlue5: [],
      dialogVisible5: false,
      // 审核
      dialogVisibleresult: false,
      AuditStatus: 0,
      Adoption: '',
      radio: '',
      // 图片
      dialogVisibleimg: false,
      imgList: [],
      srcList: [],
      Curindex: null,
      // 修改意见
      columnID: '',
      // 查看答案
      dialogVisibleAsk: false,
      //拖动元素
      DragEl: null,
      totalHeight: null,
      totalWidth: null,
      flags: false,
      position: { x: 0, y: 0 },
      nx: '',
      ny: '',
      dx: '',
      dy: '',
      xPum: '',
      yPum: '',
      // 发起oa
      showOA: false,
      audirR: '',
      auditTypeOA: null,
      userNoOA: JSON.parse(sessionStorage.getItem('userInfo')).userNo,
      violatedTerms: '',
      controlPageIndex: 0,
      isSuitable:false,
    }
  },
  mounted() {
    this.companyLisy = this.$store.state.companyUserList
    this.rowItem = JSON.parse(this.$route.query.rowItem)
    this.user = JSON.parse(sessionStorage.getItem('userInfo'))
    this.getElementHeight()
    this.getType()
    this.getTableList()
    if (this.$route.query.isNoShowDrawBtn) {
      this.showControlCenter()
    }
    var element = document.querySelector('.draggable')
    var isDragging = false
    var oldX, oldY
    element.addEventListener('mousedown', function (e) {
      isDragging = true
      oldX = e.clientX
      oldY = e.clientY
    })
    element.addEventListener('mousemove', function (e) {
      if (!isDragging) return
      var deltaX = e.clientX - oldX
      var deltaY = e.clientY - oldY
      element.style.left = element.offsetLeft + deltaX + 'px'
      element.style.top = element.offsetTop + deltaY + 'px'
      oldX = e.clientX
      oldY = e.clientY
    })
    element.addEventListener('mouseup', function () {
      isDragging = false
    })
  },
  methods: {
    showControlCenter() {
      //从控制中心跳过来调用该接口,返回值为2就隐藏oa按钮并添加确定按钮接口
      get('/api/Supervision/GetIsAuthority').then((resp) => {
        this.controlPageIndex = resp.data
      })
    },
    handleBtn(i) {
      if (this.btnList.indexOf(i) == -1) {
        this.btnList.push(i)
      } else {
        this.btnList.splice(this.btnList.indexOf(i), 1)
      }
      this.curRow.violatedTerms =
        this.btnList.toString().replace(/,/g, '：') +
        (this.btnList.length != 0 ? '：' : '')
    },
    getType() {
      if (this.$route.query.isNoShowDrawBtn) return
      get(
        '/api/MixAuditHead/GetTypeById?MixAuditHeadId=' + this.rowItem.id
      ).then((res) => {
        this.company = this.rowItem.compId
        this.$store.state.companyUserList.filter((i) => {
          if (i.id == this.company) this.mixType.companyName = i.companyName
        })

        res.data.map((i) => {
          if (i.mixAuditTypeName == '现场检查') {
            this.handleDraw1(i.areaId)
            this.mixType.auditName = '现场检查'
            this.mixType.auditId = i.auditId
            //auditStatus: 0未抽题  1已抽题
            this.mixType.auditStatus = i.auditStatus
            this.typeList.push({ name: '现场检查', id: i.auditId })
          }
          if (i.mixAuditTypeName == '文件检查') {
            this.handleDraw2(i.deptId, i.postId)
            this.mixType.fileName = '文件检查'
            this.mixType.fileId = i.auditId
            this.mixType.fileStatus = i.auditStatus
            this.typeList.push({ name: '文件检查', id: i.auditId })
          }
          if (i.mixAuditTypeName == '内外审核') {
            this.mixType.checkStatus = i.checkStatus
            this.typeList.push({ name: '内外审核', id: i.auditId })
          }
          if (i.mixAuditTypeName == '部门负责人审核') {
            this.mixType.deptHeaderName = '部门领导审核'
            this.handleDraw5(i.deptId)
            this.typeList.push({ name: '部门领导审核', id: i.auditId })
          }
        })
      })
    },
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('pdfDom').offsetTop + 130)
      })
    },
    toBack() {
      if (this.$route.query.isNoShowDrawBtn) {
        this.$router.push('/ControlPage')
        return
      }
      // this.$router.go(-1)
      this.$router.push({
        path: '/mixing',
        query: {
          fromQuery: JSON.parse(sessionStorage.getItem('fromQuery')),
        }
      })
    },
    handleDropdown1(e) {
      this.toOpen(false, e)
    },
    handleDropdown2(e) {
      this.toOpen(true, e)
    },
    handleDraw1(areaId) {
      this.titlelist1 = []
      get('/api/Area/GetByCompId?CompId=' + this.company).then((resp) => {
        if (resp.code == 200) {
          this.areaList = resp.data
          this.areaId = Number(areaId)
        }
      })
    },
    handleDraw2(deptId, postId) {
      this.getDeptList()
      this.fileDept = Number(deptId)
      this.filePost = Number(postId)
      get('/api/Post?CompId=' + this.company + '&DeptId=' + this.fileDept).then(
        (res) => {
          if (res.code == 200) {
            this.filePostList = res.data
          }
        }
      )
    },
    getTableList() {
      if(!this.rowItem.id){
        this.tableData = [
          {
            mixAuditType:1,
            fileName:'安全细则文件',
            question:'灭火器应该放置与何处？',
            auditMethod:'查看',
            lawAsk:'改正',
          }
        ];
        return;
      }
      var url = this.$route.query.isNoShowDrawBtn
        ? !this.$route.query.show7sTable //从控制中心跳过来
          ? '/api/Supervision/GetByMixHeadAuditId?MixHeadAuditId='
          : '/api/Supervision/GetBy7SAuditId?AuditId='
        : '/api/MixAuditList/GetByAuditId?AuditId=' //从混合审核跳过来
      get(url + this.rowItem.id).then((res) => {
        if (res.code == 200) {
          if (this.$route.query.isNoShowDrawBtn && !this.$route.query.show7sTable) {
            res.data.map((i) => {
              if (i.auditResult != 1 && i.auditResult != null) {
                this.tableData.push(i)
              }
            })
          } else {
            this.tableData = res.data
          }
        }
      })
    },
    plus(e) {
      if (e.extractCount < e.titleCount) e.extractCount++
    },
    sub(e) {
      if (e.extractCount > 0) e.extractCount--
    },
    test(e) {
      if (/\D/.test(e.extractCount)) e.extractCount = ''
      if (e.extractCount > e.titleCount) e.extractCount = e.titleCount
    },
    // 对表格的操作
    handleRowClick(row) {
      this.tableRowIndex = row.index
      this.curRow = row
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          'background-color': '#E2E8FA !important',
          'font-weight': 'bold !important',
          color: '#555555',
        }
      }
    },
    // 分页
    handleSizeChange(e) {
      this.curSize = e
      this.start = (this.currentPage - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    // 新增提问
    toAddQues() {
      if (this.typeId == null) {
        this.$message({
          type: 'warning',
          message: '请选择审核类型',
        })
        return
      }
      var addQuesItem = {
        id: 0,
        auditId: Number(this.typeId),
        questionId: 0,
        question: '',
        answer: '',
        lawAsk: '',
        questionRemark: '',
        correctMeasure: '',
        completion: '',
        completeTime: new Date().toISOString(),
        unqualityType: '',
        violatedTerms: '',
        remark: '',
        auditResult: 0,
        imagesCount: 0,
        isDelete: 0,
      }
      this.rowItem.type == '内外审核'
        ? (addQuesItem.questionRemark = this.question)
        : (addQuesItem.question = this.question)
      post('/api/MixAuditList', addQuesItem)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '新增成功',
            })
            this.showAddQues = false
            this.question = ''
            this.getTableList()
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(() => {
          this.$message.error('提问失败')
        })
    },
    // 点击抽题按钮
    toOpen(isAddQuestion, index) {
      this.isAddQuestion = isAddQuestion
      switch (index) {
        case 1:
          this.dialogVisible1 = true
          break
        case 2:
          this.selectDrawVlue = []
          this.dialogVisible2 = true
          this.gettitle2()
          break
        case 3:
          this.selectDrawVlue5 = []
          this.dialogVisible5 = true
          this.gettitle5()
          break
      }
    },
    getDeptList() {
      get('/api/Department/GetNoTree?CompId=' + this.company).then((res) => {
        if (res.code == 200) {
          this.fileDeptList = res.data
        }
      })
    },
    // 现场检查抽题
    chooseCt() {
      this.showCtdata = true
      this.nodeID = []
      get('/api/AreaAudit/GetAuditTypeList?AreaId=' + this.areaId).then(
        (res) => {
          this.Ctdata = res.data
        }
      )
    },
    iScheck(e, v) {
      this.nodeID = v.checkedNodes
      this.nodeKey = v.checkedKeys
    },
    gettitle1() {
      this.nodeID = this.nodeID.reverse()
      for (let index = 0; index < this.nodeID.length; index++) {
        if (this.nodeKey.includes(this.nodeID[index].upId)) {
          this.nodeKey = this.nodeKey.filter((item) => {
            return item !== this.nodeID[index].id
          })
        }
      }
      this.listID = []
      for (let index = 0; index < this.nodeKey.length; index++) {
        var item = {
          AuditTypeId: this.nodeKey[index],
        }
        this.listID.push(item)
      }
      post(
        '/api/MixAudit/GetAreaTitleList?AreaId=' +
          this.areaId +
          (this.isAddQuestion ? '&AuditId=' + this.mixType.auditId : ''),
        {
          data: JSON.stringify(this.listID),
        }
      ).then((res) => {
        this.showCtdata = false
        this.titlelist1 = res.data
        this.nodeID = []
      })
    },
    handleClose1() {
      this.dialogVisible1 = false
    },
    toReAdd1() {
      var mixAreaAudit = {
        id: 0,
        auditAreaNo: this.rowItem.auditAreaNo,
        auditAreaName: this.rowItem.auditAreaName,
        companyId: this.company + '',
        companyName: this.rowItem.companyName,
        auditType: 0,
        remark: this.remark,
        chargeUserId: this.user.id,
        planId: '',
        status: 0,
        saveTime: new Date().toISOString(),
        completeTime: new Date().toISOString(),
        saveUserId: this.user.id + '',
        saveUserNo: this.user.userNo,
        saveUserName: this.user.userName,
      }
      var url = ''
      var url1 = '/api/MixAudit/InsertAreaExtract' //第一次
      var url2 = '/api/MixAudit/AgainAreaExtract' //重新
      var url3 = '/api/MixAudit/InsertAreaAudit' //增量
      var data = {}
      if (this.isAddQuestion) {
        url = url3
        data = {
          jsonData: {
            data: JSON.stringify(this.titlelist1),
          },
          mixFileAudit: mixAreaAudit,
        }
      } else {
        url = this.mixType.auditStatus == 0 ? url1 : url2
        data = { data: JSON.stringify(this.titlelist1) }
      }
      post(
        url + '?AuditId=' + this.mixType.auditId + '&AreaId=' + this.areaId,
        data
      )
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '抽题成功',
            })
            this.getTableList()
            this.titlelist1 = []
            this.dialogVisible1 = false
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(() => {
          this.$message.error('抽题失败')
        })
    },
    // 文件检查抽题
    selectionDraw(e) {
      this.selectDrawVlue = e
    },
    gettitle2() {
      this.titlelist2 = []
      get(
        '/api/MixAudit/GetFileTitleList?CompId=' +
          this.company +
          '&DeptId=' +
          this.fileDept +
          '&PostId=' +
          this.filePost +
          (this.isAddQuestion ? '&AuditId=' + this.mixType.fileId : '')
      ).then((res) => {
        this.titlelist2 = res.data
      })
    },
    toReAdd2() {
      if (this.selectDrawVlue.length == 0) {
        this.$message({
          type: 'success',
          message: '未选择题目',
        })
        return
      }
      var url = ''
      var data = {}
      var deptName = ''
      var postName = ''
      this.fileDeptList.forEach((i) => {
        if (i.id == this.deptId) {
          deptName = i.deptmentName
        }
      })
      this.filePostList.forEach((e) => {
        if (e.id == this.filePost) {
          postName = e.postName
        }
      })
      if (this.isAddQuestion) {
        url = '/api/MixAudit/InsertFileAudit' //增量
        data = {
          jsonData: {
            data: JSON.stringify(this.selectDrawVlue),
          },
          mixFileAudit: {
            id: 0,
            companyId: this.company + '',
            companyName: this.mixType.companyName,
            deptId: Number(this.fileDept),
            deptName: deptName,
            postId: this.filePost + '',
            postName: postName,
            remark: '',
            ruleRemark: '',
            chargeUserId: this.user.chargeUserId,
            planId: '',
            auditType: 0,
            status: this.rowItem.status,
            saveTime: new Date().toISOString(),
            completeTime: new Date().toISOString(),
            saveUserId: this.user.id + '',
            saveUserNo: this.user.userNo,
            saveUserName: this.user.userName,
          },
        }
      } else {
        var url1 = '/api/MixAudit/InsertFileExtract' //第一次
        var url2 = '/api/MixAudit/AgainFileExtract' //重新
        url = this.mixType.fileStatus == 0 ? url1 : url2
        data = { data: JSON.stringify(this.selectDrawVlue) }
      }
      post(
        url +
          '?AuditId=' +
          this.mixType.fileId +
          '&CompId=' +
          this.company +
          '&DeptId=' +
          this.fileDept +
          '&PostId=' +
          this.filePost,
        data
      ).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '抽题成功',
          })
          this.getTableList()
          this.dialogVisible2 = false
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 部门领导审核抽题 //暂时没有重新抽题的功能
    gettitle5() {
      get('/api/MixAudit/GetDeptDutyTitleList?AuditId=' + this.rowItem.id).then(
        (resp) => {
          if (resp.code == 200) {
            this.titlelist5 = resp.data
          }
        }
      )
    },
    handleDraw5(deptId) {
      this.departmentHeads = deptId
      this.getDeptList()
    },
    selectionDraw5(e) {
      this.selectDrawVlue5 = e
    },
    toReAdd5() {},
    // 审核
    changeResult(row, num, score) {
      if(score == '7S不适用'){
        this.AuditStatus = score;
        this.isSuitable = num;
      }else{
        this.AuditStatus = num;
        this.isSuitable = 0;
      }
      // this.AuditStatus = num
      this.curRow = row
      this.Adoption = this.curRow.lawAsk
      this.radio = this.curRow.unqualityType
      this.btnList = []
      if (
        row.violatedTerms &&
        (this.AuditStatus === 0 ||
          this.AuditStatus === 3 ||
          this.AuditStatus === 2) &&
        this.curRow.mixAuditType != 3
      ) {
        this.btnList = row.violatedTerms.split('：')
        this.btnList.splice(this.btnList.length - 1, 1)
      }
      if (
        (num >= 0 && row.mixAuditType != 3) ||
        (num == 0 && row.mixAuditType == 3)
      ) {
        this.dialogVisibleresult = true
        this.getimg()
        return
      }
      row.auditResult = num
      var url = this.$route.query.isNoShowDrawBtn
        ? this.$route.query.show7sTable
          ? '/api/Supervision/Put7SAuditResult' //审核问题点
          : '/api/Supervision/PutMixAudit' //7s不适用
        : '/api/MixAuditList'
      put(url, row).then((res) => {
        if (res.code != '200') {
          this.$message({
            type: 'error',
            message: res.message,
          })
          row.auditResult = null
          return
        }
        this.$message({
          type: 'success',
          message: res.code == 200 ? '保存成功' : res.message,
        })
      })
    },
    toSure() {
      if (
        (this.AuditStatus === 0 ||
          this.AuditStatus === 3 ||
          this.AuditStatus === 2) &&
        this.curRow.mixAuditType != 3 &&
        !this.curRow.violatedTerms &&
        this.curRow.mixAuditType != 4 && !this.$route.query.show7sTable
      ) {
        this.$message({
          type: 'warning',
          message: '违反条款为空',
        })
        return
      }
      if(this.isSuitable){
        this.curRow.auditResult = 0
      }else{
        this.curRow.auditResult = this.AuditStatus
      }
      // this.curRow.auditResult = this.AuditStatus
      this.curRow.isSuitable = this.isSuitable
      this.curRow.lawAsk = this.Adoption
      this.curRow.unqualityType = this.radio
      var url = this.$route.query.isNoShowDrawBtn
        ? this.$route.query.show7sTable
          ? '/api/Supervision/Put7SAuditResult'
          : '/api/Supervision/PutMixAudit'
        : '/api/MixAuditList'
      put(url, this.curRow).then((res) => {
        this.$message({
          type: 'success',
          message: res.code == 200 ? '保存成功' : res.message,
        })
        if (res.code != 200) {
          this.curRow.auditResult = null
        }
      })
      this.uploadKey++
      this.$refs.upload.uploadFiles = []
      this.dialogVisibleresult = false
      this.srcList = []
      this.Adoption = ''
      this.radio = ''
    },
    // 上传图片
    openImg(e) {
      this.curRow = e
      this.dialogVisibleimg = true
      this.getimg()
    },
    handlePictureCardPreview() {
      this.dialogVisiblebig = true
    },
    async uploadFile(e) {
      if (e.file) {
        let file = await imgTypeChange(e.file)
        let newfile = await compressImage(file)
        if (newfile.size > 10000) {
          newfile = await compressImage(newfile)
        }
        this.uploadFileReq(newfile)
      }
    },
    uploadFileReq(file) {
      var formData = new FormData()
      formData.append('files', file)
      post('/api/UpLoadFiles/UploadImg', formData)
        .then((res) => {
          if (res.code == 200) {
            var data = {
              AuditListId: this.curRow.id,
              ImageRemark: '',
              ImageAddress: this.BUrl + '/uploads/' + res.value,
            }
            let url = this.$route.query.isNoShowDrawBtn && this.$route.query.show7sTable?'/api/AreaAuditListImage':'/api/MixAuditListImage'
            post(url, {
              data: JSON.stringify([data]),
            }).then(() => {
              this.getimg()
            })
          } else {
            this.uploadKey++
            this.$refs.upload.uploadFiles = []
            this.$message.error(res.message)
          }
        })
        .catch(() => {
          this.$refs.upload.uploadFiles = []
          this.uploadKey++
          this.$message.error('上传失败')
        })
    },
    getimg() {
      this.srcList = []
      this.$refs.upload.uploadFiles = []
      this.uploadKey++
      let url = this.$route.query.isNoShowDrawBtn && this.$route.query.show7sTable?'/api/AreaAuditListImage/':'/api/MixAuditListImage/'
      get(url + this.curRow.id).then((res) => {
        this.imgList = res.value
        res.value.forEach((element) => {
          this.srcList.push(element.picNameAndAddr)
        })
        this.curRow.imagesCount = this.srcList.length
      })
    },
    overimg(index) {
      this.Curindex = index
    },
    toPostImg() {
      this.dialogVisibleimg = false
      this.dialogVisibleAsk = false
      this.$refs.upload.uploadFiles = []
      this.uploadKey++
    },
    delImg(item) {
      this.imgList.forEach((e) => {
        if (e.picNameAndAddr == item) {
          this.$confirm('此操作将永久删除该图片', '是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              let url = this.$route.query.isNoShowDrawBtn && this.$route.query.show7sTable?'/api/AreaAuditListImage/':'/api/MixAuditListImage/'
              deleteMethod(url + '?Id=' + e.id).then(() => {
                this.getimg()
              })
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消删除',
              })
            })
        }
      })
    },
    // 修改审核意见
    handleCell(row, column) {
      this.curRow = row
      this.columnID = column.id
    },
    compare(e) {
      this.curlawask = e
    },
    Updata(row, e) {
      this.columnID = ''
      if (this.curlawask == e) return
      let url = this.$route.query.isNoShowDrawBtn && this.$route.query.show7sTable?'/api/Supervision/Put7SAuditResult':'/api/MixAuditList'
      put(url, row)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: res.code == 200 ? '更改成功' : res.message,
            })
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(() => {
          this.$message.error('审核失败')
        })
    },
    // 完成检查
    confirmSave() {
      var isSave = true
      this.tableData.forEach((e) => {
        if (e.auditResult == null) isSave = false
      })
      if (!isSave) {
        this.$message({
          type: 'success',
          message: '未完成审核',
        })
        return
      }
      if (this.rowItem.status == 2) {
        this.$message({
          type: 'success',
          message: '已经审核完成，请勿重复完成',
        })
        return
      }
      put('/api/MixAuditHead?MixAuditHeadId=' + this.rowItem.id)
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: 'success',
              message: '检查成功',
            })
            this.rowItem.status = 2
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error('检查失败')
        })
    },
    // 删除
    delItem() {
      if (this.rowItem.status == 2) {
        this.$message({
          type: 'success',
          message: '审核完成，不允许删除！',
        })
        return
      }
      if (this.tableRowIndex == null) {
        this.$message({
          type: 'info',
          message: '未选择题目',
        })
        return
      }
      let role = JSON.parse(sessionStorage.getItem('userInfo')).roleName;
      let fromQuery = JSON.parse(sessionStorage.getItem('fromQuery'));
      if(role.includes('股份公司管理员') || (fromQuery == '3' && role.includes('公司管理员'))){
        this.$confirm('此操作将永久删除该题目', '是否继续?', '提示')
          .then(() => {
            deleteMethod('/api/MixAuditList?Id=' + this.curRow.id)
              .then((res) => {
                if (res.code == 200) {
                  this.tableData.splice(this.tableRowIndex, 1)
                  this.$message({
                    type: 'success',
                    message: '删除成功',
                  })
                } else {
                  this.$message.error(res.message)
                }
              })
              .catch(() => {
                this.$message.error('删除失败')
              })
          })
          .catch(() => {})
      }else{
        if (this.curRow.questionId != 0) {
          this.$message({
            type: 'info',
            message: '非自定义题目不可删除',
          })
          return
        }
      }
    },
    // 查看答案
    isChoose(e) {
    console.log(e);
      this.srcList = []
      this.curRow = e
      this.dialogVisibleAsk = true
      if (e.questionImageAddr) {
        var imgdata = e.questionImageAddr.split(",");
        imgdata.forEach((e) => {
          this.srcList.push(this.BUrl + "/uploads/" + e);
        });
      }
    },
    //控制中心的混合检查、7s检查调用确定接口
    clickSureBtn(row, index) {
      this.$confirm('是否继续操作？，确定后该数据交由公司管理员审核').then(() => {
        var url =
          index == 1
            ? '/api/Supervision/SubmitMixAudit'
            : '/api/Supervision/Submit7SAudit'
        put(url + '?AuditListId=' + row.id)
          .then((resp) => {
            if (resp.code == 200) {
              this.$message({
                type: 'success',
                message: '已确定',
              })
              this.getTableList()
            } else {
              this.$message.error(resp.message)
            }
          })
          .catch(() => {
            this.$message.error('系统异常，请稍后再试')
          })
      })
    },
    // 发起oa
    showOADialog(row) {
      this.curRow = row
      this.showOA = true
      if (this.curRow.auditResult == 0) {
        this.audirR = this.curRow.unqualityType + '项'
      } else if (this.curRow.auditResult == 2) {
        this.audirR = '纠正'
      } else if (this.curRow.auditResult == 3) {
        this.audirR = '改进'
      }
    },
    requreOA() {
      if (!this.audirR) {
        this.$message({
          type: 'warning',
          message: '原因状态为空',
        })
        return
      }
      if (!this.curRow.lawAsk) {
        this.$message({
          type: 'warning',
          message: '审核意见为空',
        })
        return
      }
      axios({
        url:
          'http://43.139.69.79:91/api/NCSeason?userNo=' +
          this.userNoOA +
          '&audirR=' +
          this.audirR +
          '&myid=' +
          this.curRow.id +
          '&auditRRes=' +
          String(this.curRow.lawAsk) +
          '&auditType=' +
          Number(this.auditTypeOA) +
          '&auditTerms=' +
          String(this.curRow.violatedTerms),
        method: 'GET',
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '发出OA成功',
            })
            this.showOA = false
          } else {
            this.$message.error(res.data.message)
          }
        })
        .catch(() => {
          this.$message.error('发出OA失败')
        })
    },
    exportExcel() {
      this.showExportExal = true
    },
    handleExportExal() {
      this.sureExportExal = true
      var isOk = null
      isOk = exportExcel('#exportTable', '混合审核.xlsx')
      if (isOk != null) {
        this.sureExportExal = false
      }
    },
    table7sIsPass(index, row) {
      put('/api/Supervision/Put7SAudit', { id: row.id, isPass: index })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: index == 1 ? '通过成功' : '驳回成功',
            })
            this.tableData = []
            this.getTableList()
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(() => {
          this.$message.error('系统异常,请稍后再试')
        })
    },
  },
}
</script>

<style lang="less">
@import '../../assets/style/Examine_CSS.css';

.body {
  background-color: #e7e9ec;
  height: 100vh;
}
.go-back {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.draggable {
  position: absolute;
  cursor: move;
  width: 40%;
  z-index: 999;
  top: 2px;
  left: 20%;
  box-shadow: 0px 0px 10px silver;
  .el-form-item {
    margin-bottom: 5px !important;
  }
  .header {
    padding: 10px;
    background-color: #4274c0;
    color: white;
  }
  > div {
    background: white;
    .footer {
      width: 100%;
      display: block;
      text-align: end;
    }
    .img-list {
      max-height: 150px;
      overflow-y: scroll;
    }
  }
}
.draggable:active {
  cursor: grabbing;
}
.error-dialog2 .el-dialog {
  margin-left: 200px;
}
.addQuestionDialog {
  .el-form-item__label {
    font-size: 12px !important;
    font-weight: bold;
  }
  .el-input__inner {
    background-color: white !important;
  }
}
</style>
